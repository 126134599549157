// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
// helpers
import { getTypeKey } from '@helpers/strapi'
// context
import { useStrapiContext } from '@context/strapiContext'
// components
import PageRecentlyViewed from '@shared/recently-viewed'
import PageMarkdown from './content-types/Markdown'
import PageBanner from './content-types/Banner'
import PageSearchQuery from './content-types/SearchQuery'
import PageButtonList from './content-types/ButtonList'
import PageGrid from './content-types/Grid'
import PageSlider from './content-types/SliderWrapper'
import PageAccordion from './content-types/Accordion'
import PageAccordionList from './content-types/AccordionList'
import PageFinanceBannerGroup from './content-types/FinanceBannerGroup'
import PageHtml from './content-types/HTML'
import PageContentGroup from './content-types/ContentGroup'
import PageDeliveryWidget from './content-types/DeliveryWidget'
import PageValidateCoupon from './content-types/ValidateCoupon'
import PageImageList from './content-types/ImageList'
import PageMosaicGrid from './content-types/MosaicGrid/MosaicGrid'
import PageSpecial from './content-types/Specials'
import PageVideo from './content-types/Video'
import PageTabs from './content-types/Tabs'
import PageTileGrid from './content-types/TileGrid'
import PageSliderFilterList from './content-types/SliderFilterList'
import PageImageGallery from './content-types/ImageGallery/ImageGallery'
import PageContentSection from './content-types/ContentSection'
import PagePersonalizedModal from './content-types/PersonalizedModal'
import BaseHeader from './content-types/Header'
import PageBannerSwiper from './content-types/BannerSwiper/BannerSwiper'
import PageAnchorNavigation from './content-types/AnchorNavigation'
import PageBlog from './content-types/Blog/index'
import BannerBannerText from './content-types/BannerText'
import PageSignUpForm from './content-types/SignUpForm/SignUpForm'

const avaiableTypes = {
  // ? Breadcrumbs are rendered at the top of the Page by default, there is no need to render them here.
  PageBreadCrumbs: null,
  PageBanner,
  PageMarkdown,
  PageSearchQuery,
  PageButtonList,
  PageGrid,
  PageSlider,
  PageAccordion,
  PageAccordionList,
  PageRecentlyViewed,
  PageFinanceBannerGroup,
  PageHtml,
  PageDeliveryWidget,
  PageImageList,
  PageSpecial,
  PageVideo,
  PageTabs,
  PageTileGrid,
  PageSliderFilterList,
  PageImageGallery,
  BaseHeader,
  PageBannerSwiper,
  PagePersonalizedModal,
  PageAnchorNavigation,
  PageBlog,
  BannerBannerText,
  PageSignUpForm,
  PageMosaicGrid,
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'block',
    maxWidth: '104rem',
    '& .MuiGrid-spacing-xs-2:not(.grid-wrapper), & .MuiGrid-spacing-xs-4:not(.grid-wrapper), & .MuiGrid-spacing-xs-6:not(.grid-wrapper)': {
      margin: 0,
    },
    '& > div:not(.page-content-group)': {
      '&:not(.grid-wrapper)': {
        width: '100%',
      },
    },
    '& > div.page-content-group > div': {
      '&:not(.grid-wrapper)': {
        width: '100%',
      },
    },
  },
}))

const ContentGenerator = ({ horizontalSpacing = { LeftPadding: 0, RightPadding: 0 } }) => {
  const classes = useStyles()
  const {
    page: { ContentTypes, SEO },
  } = useStrapiContext()

  if (ContentTypes && Array.isArray(ContentTypes)) {
    return (
      <Grid container className={classes.container} id="strapi-page-container">
        {ContentTypes.map((contentData, index) => {
          if (!contentData?.__typename) {
            return null
          }

          if (contentData.__typename === 'PageValidateCoupon' && contentData.InvalidCouponRedirect) {
            return <PageValidateCoupon key={getTypeKey(contentData)} link={contentData.InvalidCouponRedirect} />
          }

          if (contentData.__typename === 'PageContentGroup' || contentData.__typename === 'PageContentSection') {
            const availableContentTypes = {
              PageContentGroup,
              PageContentSection,
            }
            const ContentComponent = availableContentTypes[contentData.__typename]

            return ContentComponent ? (
              <ContentComponent
                data={
                  contentData.__typename === 'PageContentGroup'
                    ? contentData?.ContentGroup
                    : contentData?.ContentSection
                }
                key={getTypeKey(contentData)}
                index={index}
                horizontalSpacing={horizontalSpacing}
              />
            ) : null
          }

          const Component = avaiableTypes[contentData.__typename]

          return Component ? (
            <Component
              contentIndex={index}
              data={contentData}
              key={getTypeKey(contentData)}
              horizontalSpacing={horizontalSpacing}
            />
          ) : null
        })}
        {SEO?.Accordion?.Accordion && <PageAccordion data={SEO?.Accordion?.Accordion} gridWrapper />}
      </Grid>
    )
  }
  return null
}

ContentGenerator.propTypes = {
  horizontalSpacing: PropTypes.object,
}

export default ContentGenerator
