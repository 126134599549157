// adding comment to push new build; will remove

// dependencies
import React, { useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useMediaQuery, responsiveFontSizes, createTheme, ThemeProvider, Grid } from '@material-ui/core'
import { theme as rtgTheme } from '@rtgdev/design-system'
// helpers
import { selectRegionBasedContent, hasSearchQuery } from '@helpers/strapi'
import { getRegion } from '@helpers/geo-location'
import { getURLParam } from '@helpers/general'
import { checkPLALocation } from '@helpers/static-pdp'
// hooks
import useDisabledCoupons from '@hooks/useDisabledCoupons'
import usePageAnalytics from '@hooks/usePageAnalytics'
// context
import { StrapiContextProvider } from '@context/strapiContext'
// components
import Layout from '@components/layout'
import ProductQuickView from '@components/product/product-quick-view'
import ContentGenerator from './ContentGenerator'
import PageSEO from './content-types/SEO'
import PageBreadcrumbs from './content-types/Breadcrumbs'
import Banner from './content-types/Banner'
import { setPixleeEventListener, removePixleeEventListener } from './content-types/HTML/PixleeWidget'

const pageTheme = responsiveFontSizes(
  createTheme({
    ...rtgTheme,
    spacing: 5,
    breakpoints: {
      values: {
        xs: 0,
        sm: 639,
        md: 1024,
        lg: 1480,
        xl: 1920,
      },
    },
  }),
)

function isProductListingPage(pageType = '') {
  const plpTypes = {
    plp: true,
    sub_category: true,
    other: true,
  }
  return plpTypes[pageType] || false
}

const StrapiCMSPage = ({ data, navigate, params, pageContext }) => {
  const dispatch = useDispatch()

  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))

  const page = data?.allStrapiPage?.edges?.[0]?.node || {}
  const { id, testId, PageType, Title, Route, HorizontalSpacing = {} } = page

  const horizontalSpacing =
    PageType === 'article' && !isMobile
      ? { LeftPadding: HorizontalSpacing?.LeftPadding, RightPadding: HorizontalSpacing?.RightPadding }
      : undefined

  const previous_page = typeof window !== 'undefined' ? window.previousPath : ''

  usePageAnalytics({ cid: id, title: Title, path: Route, type: PageType }, null, previous_page)
  const region = getRegion()
  const disabledCoupons = useDisabledCoupons()

  const matchPath = pageContext?.matchPath
  const SEO = page?.SEO?.SEO

  const defaultFinanceBanner = data?.allStrapiFinanceBannerGroup?.edges?.[0]?.node[region] || {}

  const ContentTypes = useMemo(() => selectRegionBasedContent(region, page), [region, page])
  const campaignSku = useMemo(() => getURLParam('campaign_sku'), [])

  useEffect(() => {
    setPixleeEventListener()
    return () => {
      removePixleeEventListener()
    }
  }, [])

  useEffect(() => {
    if (ContentTypes) {
      const foundPixleeWidget = ContentTypes.find(
        contentType =>
          contentType.__typename === 'PageHtml' && contentType.HTML?.HTML?.toLowerCase?.().includes('pixlee'),
      )
      if (foundPixleeWidget) window?.Pixlee?.init?.({ apiKey: 'hNRL7YymtOeY7jfO2XFe' })
    }
  }, [ContentTypes])

  useEffect(() => {
    if (campaignSku) checkPLALocation(dispatch)
  }, [campaignSku, dispatch])

  const isPLP = useMemo(() => isProductListingPage(PageType) || hasSearchQuery(ContentTypes), [PageType, ContentTypes])

  const isFinanceBannerOnPage = ContentTypes?.some(
    ({ __typename, Banner: InPageBanner }) =>
      __typename === 'PageBanner' && InPageBanner?.id === defaultFinanceBanner?.Banner?.id,
  )

  const { BreadCrumbs } = useMemo(
    () => ContentTypes?.find(({ __typename, BreadCrumbs: BC }) => __typename === 'PageBreadCrumbs' && BC) || {},
    [ContentTypes],
  )

  const contextValue = {
    navigate,
    params,
    isMobile,
    disabledCoupons,
    page: { id, PageType, Title, Route, SEO, ContentTypes, BreadCrumbs, isPLP, matchPath },
  }

  const { Crumbs } = BreadCrumbs || {}

  // Requested by FD-2983 (https://rtgjira.atlassian.net/browse/FD-2983).
  const isRouteWithFinanceBarSuppressed = useMemo(
    () => contextValue.page.Route === '/outlet/weekly-specials' || contextValue.page.Route === '/business-plus/form',
    [contextValue.page.Route],
  )

  return (
    <ThemeProvider theme={pageTheme}>
      <StrapiContextProvider value={contextValue}>
        <PageSEO data={SEO} breadcrumbs={Crumbs} />
        <main id="content" aria-label="Content" tabIndex="-1" className="generated-page" data-testid={testId}>
          <PageBreadcrumbs horizontalSpacing={horizontalSpacing} />
          <ContentGenerator horizontalSpacing={horizontalSpacing} />
          {campaignSku && <ProductQuickView sku={campaignSku} componentPage={`${PageType}-pla-link`} />}
          {isPLP && !isFinanceBannerOnPage && !isRouteWithFinanceBarSuppressed && (
            <Grid container>
              <Banner data={defaultFinanceBanner} />
            </Grid>
          )}
        </main>
      </StrapiContextProvider>
    </ThemeProvider>
  )
}

StrapiCMSPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}

const PageComponent = props => (
  <Layout>
    <StrapiCMSPage {...props} />
  </Layout>
)

export default PageComponent

export const StrapiPageFragment = graphql`
  fragment StrapiPageFragment on StrapiPage {
    id
    testId
    PageType
    Title
    Route
    SEO {
      ...StrapiPageSeoFragment
    }
    HorizontalSpacing {
      LeftPadding
      RightPadding
    }
    DefaultContent {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PagePersonalizedModal {
        PersonalizedModal {
          ...personalizedModalFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageValidateCoupon {
        InvalidCouponRedirect {
          ...StrapiLinkFragment
        }
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageContentSection {
        ContentSection {
          ...StrapiContentSectionFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAnchorNavigation {
        AnchorNavigation {
          ...PageAnchorNavigationFragment
        }
      }
      ... on PageBlog {
        Blog {
          ...StrapiPageBlogFragment
        }
      }
      ... on BannerBannerText {
        ...StrapiBannerTextFragment
      }
      ... on PageSignUpForm {
        ...StrapiPageSignUpFormFragment
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
    Content_SE {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PagePersonalizedModal {
        PersonalizedModal {
          ...personalizedModalFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageValidateCoupon {
        InvalidCouponRedirect {
          ...StrapiLinkFragment
        }
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageContentSection {
        ContentSection {
          ...StrapiContentSectionFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAnchorNavigation {
        AnchorNavigation {
          ...PageAnchorNavigationFragment
        }
      }
      ... on PageBlog {
        Blog {
          ...StrapiPageBlogFragment
        }
      }
      ... on BannerBannerText {
        ...StrapiBannerTextFragment
      }
      ... on PageSignUpForm {
        ...StrapiPageSignUpFormFragment
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
    Content_FL {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PagePersonalizedModal {
        PersonalizedModal {
          ...personalizedModalFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageValidateCoupon {
        InvalidCouponRedirect {
          ...StrapiLinkFragment
        }
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageContentSection {
        ContentSection {
          ...StrapiContentSectionFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAnchorNavigation {
        AnchorNavigation {
          ...PageAnchorNavigationFragment
        }
      }
      ... on PageBlog {
        Blog {
          ...StrapiPageBlogFragment
        }
      }
      ... on BannerBannerText {
        ...StrapiBannerTextFragment
      }
      ... on PageSignUpForm {
        ...StrapiPageSignUpFormFragment
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
    Content_TX {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PagePersonalizedModal {
        PersonalizedModal {
          ...personalizedModalFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageValidateCoupon {
        InvalidCouponRedirect {
          ...StrapiLinkFragment
        }
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageContentSection {
        ContentSection {
          ...StrapiContentSectionFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAnchorNavigation {
        AnchorNavigation {
          ...PageAnchorNavigationFragment
        }
      }
      ... on PageBlog {
        Blog {
          ...StrapiPageBlogFragment
        }
      }
      ... on BannerBannerText {
        ...StrapiBannerTextFragment
      }
      ... on PageSignUpForm {
        ...StrapiPageSignUpFormFragment
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
    Content_OOM {
      __typename
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBanner {
        __typename
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageBreadCrumbs {
        __typename
        BreadCrumbs {
          ...StrapiPageBreadCrumbsFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageGrid {
        __typename
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageContentGroup {
        ContentGroup {
          ...StrapiContentGroupFragment
        }
      }
      ... on PagePersonalizedModal {
        PersonalizedModal {
          ...personalizedModalFragment
        }
      }
      ... on PageAccordion {
        Accordion {
          ...StrapiPageAccordionFragment
        }
      }
      ... on PageAccordionList {
        AccordionList {
          ...StrapiPageAccordionListFragment
        }
      }
      ... on PageFinanceBannerGroup {
        FinanceBannerGroup {
          ...StrapiFinanceBannerGroupFragment
        }
      }
      ... on PageRecentlyViewed {
        ShowRecentlyViewed
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on PageDeliveryWidget {
        ...DeliveryWidgetFragment
      }
      ... on PageValidateCoupon {
        InvalidCouponRedirect {
          ...StrapiLinkFragment
        }
      }
      ... on PageSpecial {
        Specials {
          ...StrapiSpecialsFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageSliderFilterList {
        SliderFilterList {
          ...SliderFilterListFragment
        }
      }
      ... on PageImageGallery {
        ImageGallery {
          ...ImageGalleryFragment
        }
      }
      ... on PageContentSection {
        ContentSection {
          ...StrapiContentSectionFragment
        }
      }
      ... on BaseHeader {
        ...StrapiHeaderFragment
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageTabs {
        TabGroup {
          ...StrapiPageTabGroupFragment
        }
      }
      ... on PageAnchorNavigation {
        AnchorNavigation {
          ...PageAnchorNavigationFragment
        }
      }
      ... on PageBlog {
        Blog {
          ...StrapiPageBlogFragment
        }
      }
      ... on BannerBannerText {
        ...StrapiBannerTextFragment
      }
      ... on PageSignUpForm {
        ...StrapiPageSignUpFormFragment
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
  }
`

export const StrapiPageQuery = graphql`
  query strapiPageQuery($pageId: String!) {
    allStrapiPage(filter: { id: { eq: $pageId } }) {
      edges {
        node {
          ...StrapiPageFragment
        }
      }
    }
    allStrapiFinanceBannerGroup(filter: { Title: { eq: "Production Finance Banner Group" } }) {
      edges {
        node {
          id
          Title
          SE {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          FL {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          TX {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          PR {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
        }
      }
    }
  }
`
