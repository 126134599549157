import { useQuery } from 'react-query'

/**
 * Fetches coupons data
 * @returns {any[]} Disabled coupons data array
 */
const fetchDisabledCoupons = () => {
  const URL = `${process.env.GATSBY_DISABLED_COUPONS_SERVICE}?env=${process.env.GATSBY_STRAPI_URL}`
  return fetch(URL).then(response =>
    !response.ok
      ? Promise.reject(new Error(`Error fetching DISABLED COUPONS: ${response.status} | ${response.statusText}`))
      : response.json().then(json => json?.disabledCoupons || []),
  )
}

/**
 * Fetches, caches and memonizes disabled coupons data
 * @returns {{ isLoading: boolean, data: any[] }}
 */
const useDisabledCoupons = () => {
  const { isLoading, error, data } = useQuery('disabled-coupons', fetchDisabledCoupons)

  if (error) {
    console.error(error)
    return { isLoading, data: [], error }
  }

  return { isLoading, data }
}

export default useDisabledCoupons
